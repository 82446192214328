import Service from "../Service";
import Vue from "vue";
const resource = "/TransportPrice/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationParameters", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "saveParameters", obj, {
            params: { requestID: requestID },
        });
    },

};