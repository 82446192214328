<template>
  <v-container>
    <div>
      <s-crud
        :filter="filter"
        :config="config"
        edit
        add
        @save="save($event)"
        @rowSelected="rowSelected($event)"
        search-input
        no-full
      >
        <template v-slot:filter>
          <v-container>
            <v-row justify="center" style="margin-top: auto;">
              <v-col lg="4" md="4" cols="12">
                <s-select-definition
                  label="Cultivo"
                  :def="1173"
                  v-model="filter.TypeCultive"
                >
                </s-select-definition>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template scope="props">
          <v-container>
            <v-row justify="center" style="margin-top: auto;">
              <v-col lg="5" md="5" cols="12">
                <s-select-definition
                  label="Cultivo"
                  :def="1173"
                  v-model="props.item.TypeCultive"
                />
              </v-col>

              <v-col lg="5" md="5" cols="12">
                <s-select-definition
                  :def="1229"
                  label="Costo"
                  v-model="props.item.TypeCoste"
                />
              </v-col>
            </v-row>
            <v-row justify="center" style="margin-top: auto;">
              <v-col lg="3" md="3" cols="12">
                <s-select-definition
                  :def="1238"
                  label="Parametro"
                  v-model="props.item.TypeTransportParam"
                  @input="input($event)"
                />
              </v-col>

              <v-col lg="2" md="2" cols="12" v-if="chkTypeValue">
                <s-select-definition
                  :def="1028"
                  label="Tipo Valor"
                  v-model="props.item.TypeValue"
                />
              </v-col>
              <v-col lg="2" md="2" cols="12" v-else-if="!chkTypeValue">
                <s-text label="Def. ID" v-model="props.item.TspValue" />
              </v-col>

              <v-col lg="3" md="3" cols="6">
                <s-text
                  :label="'Nombre: ' + sNameParameter"
                  v-model="props.item.TspName"
                />
              </v-col>
              <v-col lg="1" md="1" cols="3">
                <v-checkbox label="Calculo Base" v-model="props.item.TspCalculate"> </v-checkbox>
              </v-col>
              <v-col lg="1" md="1" cols="3">
                <v-checkbox label="Calculo Adicional" v-model="props.item.TspCalculateAditional"> </v-checkbox>
              </v-col>

              <v-col lg="8" md="8" cols="9" style="margin-top: -30px;">
                <s-text
                  label="Descripcion"
                  v-model="props.item.TspDescription"
                />
              </v-col>

              <v-col lg="2" md="2" cols="3" style="margin-top: -30px;">
                <s-text label="Orden" v-model="props.item.TspSort" />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </s-crud>
    </div>
  </v-container>
</template>
<script>
//Service
import _sTransportParametersService from "@/services/Technicalassistance/TransportParametersService";

//Component
import SSelectDefinition from "@/components/Utils/SSelectDefinition.vue";

export default {
  components: { SSelectDefinition },
  props: {},
  data: () => ({
    sNameParameter: "Parametro",
    chkTypeValue: false,
    selected: {},

    filter: { TypeCultive: 0 },
    config: {
      service: _sTransportParametersService,
      model: {
        TspID: "ID",
      },
      headers: [
        { text: "Cultivo", value: "TypeCultiveName", width: 20 },
        { text: "Costo", value: "TypeCosteName", width: 20 },
        { text: "Parametro", value: "TypeTransportParamName", width: 20 },
        { text: "Tipo Valor", value: "TypeValueName", width: 20 },
        { text: "Valor", value: "TspValue", width: 20 },
        { text: "Nombre Parametro", value: "TspName", width: 20 },
        { text: "Descripcion", value: "TspDescription", width: 20 },
        { text: "Orden", value: "TspSort", width: 10},
      ],
    },
  }),
  methods: {
    rowSelected(val) {
      this.selected = val[0];
      console.log("rowSelected: ", this.selected);
    },

    input(val) {
      console.log(val);
      if (val !== undefined) {
        //this.sNameParameter = val.DedDescription;

        if (val == 1) {
          this.chkTypeValue = true;
        } else {
          this.chkTypeValue = false;
        }
      }
    },

    isValidated(val) {
      console.log("isValidated", val);

      var isValidated = true;
      if (val.TypeCultive == null || val.TypeCultive == 0) {
        this.$fun.alert("Falta Cultivo", "warning");
        isValidated = false;
        return;
      }

      if (val.TypeCoste == null || val.TypeCoste == 0) {
        this.$fun.alert("Falta tipo de Costo", "warning");
        isValidated = false;
        return;
      }

      if (val.TypeTransportParam == null || val.TypeTransportParam == 0) {
        this.$fun.alert("Falta Parametro", "warning");
        isValidated = false;
        return;
      }

      if (val.TspName == "" || val.TspName == null) {
        this.$fun.alert("Ingrese Nombre de Parametro", "warning");
        isValidated = false;
        return;
      }

      if (this.chkTypeValue == true && val.TypeValue == null) {
        this.$fun.alert("Falta Tipo de Valor", "warning");
        isValidated = false;
        return;
      }

      if (this.chkTypeValue == false && val.TspValue.length == 0) {
        this.$fun.alert("Falta Valor", "warning");
        isValidated = false;
        return;
      }

      /* if(val.TypeValue == null || val.TypeValue == 0){
            this.$fun.alert("Falta Tipo Valor", "warning");
            isValidated = false;
            return;
        } */

      return isValidated;
    },

    save(val) {
      if (!this.isValidated(val)) {
        return;
      }

      if (this.chkTypeValue == false) {
        val.TypeValue = null;
      }

      if (this.chkTypeValue == true) {
        val.TspValue = null;
      }

      val.SecStatus = 1;
      val.UsrCreateID = this.$fun.getUserID();
      //val.TypeTransportParam = val.TypeTransportParam.DedValue;
      console.log("Guardar", val);
      val.save();
    },
  },
};
</script>
